.verticalLine {
    transform: rotate(90deg);
    color: #656b76 !important;
    cursor: initial !important;
}

option {
    width: 300px !important;
    color: 'red !important';
}

div.jodit-popup{
    z-index: 10000 !important;
}