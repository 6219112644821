@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
html {
  margin: 0;
  padding: 0;
  height:100%;
}

* {
  font-family: 'Montserrat', sans-serif !important;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height:100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  height: 100%;
}
.verticalLine {
    transform: rotate(90deg);
    color: #656b76 !important;
    cursor: auto !important;
    cursor: initial !important;
}

option {
    width: 300px !important;
    color: 'red !important';
}

div.jodit-popup{
    z-index: 10000 !important;
}
.loginContainer{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position:fixed;
    height:100%;
    width:100%;
    background-color: #aee1f9;
    background-image: linear-gradient(315deg, #aee1f9 0%, #f6ebe6 74%);
    top:0px;
    left:0px;
}

.login{
    width:400px;
    height:375px;
    border: 1px solid #eeeeee;
    padding: 30px;
    box-sizing:border-box;
    background-color:#ffffff;
    display:flex;
    align-items:center;
    flex-direction: column;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
}

.loginInput{
    width:100%;
    margin-top:10px !important;
}

.loginButton{
    width: 150px;
    box-shadow: 1px 1px 3px 0px rgba(0,0,0,0.35);
    margin-top:30px !important;
    height:35px;
}

.loginRememberMe{
    width:100%
}

.loginRememberMeLabel span{
    font-size:14px !important;
}
.sidebar{
    height: 100%; 
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    order: -1;
    background-color:rgba(0,0,0,0.001);
    border-right: 1px solid rgba(0,0,0,0.1);
    width:215px; 
    align-items: center;
  }
.appContainer {
    margin: 0;
    padding: 0;
    height: 100%;
    box-sizing: border-box;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.toastSuccess{
  background-color: #00458B !important
}

.toastError{
  background-color: #d32f2f !important
}

.toastErrorBody > div, .toastSuccessBody > div {
    height: auto
}

.Toastify__toast{
    min-height: 50px !important
}

.Toastify__close-button{
    margin-top: 9px;
    padding: 0px 12px 0px 10px;
    font-weight: normal
}

.Toastify__toast-container{
    width: 360px !important
}

.commentImg{
    border: 2px solid transparent;
}

.commentImg:hover{
    cursor: pointer;
    border: 2px solid #e0e0e0
}

.react-tel-input .special-label{
  left: 7px;
  font-size: 11px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.54); 
  font-family: 'Montserrat', sans-serif !important;
}

div.calendarContainer .MuiTableRow-root{
  height: 0 !important;
}

.rw-i-chevron-right{
  visibility: hidden;
}

.rw-i-chevron-right:before{
  content: '>';
	visibility: visible;
}

.rw-i-chevron-left{
  visibility: hidden;
}

.rw-i-chevron-left:before{
  content: '<';
  visibility: visible;
}


@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

